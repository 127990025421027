document.addEventListener('DOMContentLoaded', function() {
  const desktopMenuIcons = document.querySelectorAll('.desktop-menu-icon');
  const toggler = document.querySelector('.toggler');
  const cardMenu = document.querySelector('.card--menu');
  const iconMenu = document.querySelector('.icon--menu');
  const iconClose = document.querySelector('.icon--close');

  function toggleActiveClass(element) {
    if (element.classList.contains('active__menu')) {
      element.classList.remove('active__menu');
    } else {
      element.classList.add('active__menu');
    }
  }

  function toggleCardMenuExpansion() {
    if (toggler.classList.contains('expanded')) {
      cardMenu.classList.add('expanded');
    } else {
      cardMenu.classList.remove('expanded');
    }
  }

  function toggleIcon() {
    toggler.classList.toggle('expanded');
    toggleCardMenuExpansion();

    if (toggler.classList.contains('expanded')) {
      iconMenu.style.display = 'none';
      iconClose.style.display = 'block';
    } else {
      iconMenu.style.display = 'block';
      iconClose.style.display = 'none';
    }

    desktopMenuIcons.forEach(function(icon) {
      toggleActiveClass(icon);
    });
  }

  toggler.addEventListener('click', toggleIcon);

  // Add click event listener to desktop-menu-icons
  desktopMenuIcons.forEach(function(icon) {
    icon.addEventListener('click', function() {
      toggleActiveClass(icon);
      toggleCardMenuExpansion();
    });
  });
});









